import { AsyncParser } from "json2csv"
import { Feedback } from "src/types/FeedbackTypes"

export const convertJsonToCsv = async (jsonData: Feedback[]) => {
  const data = JSON.stringify(jsonData)
  const transformOpts = { highWaterMark: 8192 }

  const asyncParser = new AsyncParser(undefined, transformOpts)

  let csv = ""
  asyncParser.processor
    .on("data", (chunk) => (csv += chunk.toString()))
    .on("end", () => {
      var blobData = [csv]
      var blob = new Blob(blobData, {
        type: "text/csv",
      })

      var blobURL =
        window.URL && window.URL.createObjectURL
          ? window.URL.createObjectURL(blob)
          : window.webkitURL.createObjectURL(blob)
      var tempLink = document.createElement("a")
      tempLink.style.display = "none"
      tempLink.href = blobURL
      tempLink.setAttribute("download", "feedback.csv")

      // Safari thinks _blank anchor are pop ups. We only want to set _blank
      // target if the browser does not support the HTML5 download attribute.
      // This allows you to download files in desktop safari if pop up blocking
      // is enabled.
      if (typeof tempLink.download === "undefined") {
        tempLink.setAttribute("target", "_blank")
      }

      document.body.appendChild(tempLink)
      tempLink.click()

      // Fixes "webkit blob resource error 1"
      setTimeout(function () {
        document.body.removeChild(tempLink)
        window.URL.revokeObjectURL(blobURL)
      }, 200)
    })
    .on("error", (err) => console.error(err))

  asyncParser.input.push(data)
  asyncParser.input.push(null) // Sending `null` to a stream signal that no more data is expected and ends it.
}
