import { useCallback } from "react"
import { useGetAccessToken } from "src/auth/useGetAccessToken"
import useSWR from "swr"
import { useMsal } from "@azure/msal-react"

export function useGetUser() {
  const { accounts } = useMsal()
  return accounts[0]?.name ?? "Ukjent"
}

export function useFetcher() {
  const getAccessToken = useGetAccessToken()

  return useCallback(
    async (url: string, init?: RequestInit) => {
      const accessToken = await getAccessToken()
      const initWithHeaders = {
        ...init,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }

      const response = await fetch(url, initWithHeaders)

      if (response.ok) {
        const textResponse = await response.text()
        return textResponse ? JSON.parse(textResponse) : {}
      } else {
        throw new Error(response.status + " " + response.statusText)
      }
    },
    [getAccessToken],
  )
}

export function useSuspenseFetch<T>(url: string) {
  const fetcher = useFetcher()

  return useSWR<T>(url, fetcher, { suspense: false })
}

export function usePostRequest<Request, Response>() {
  const fetcher = useFetcher()

  return useCallback(
    async (url: string, data: Request) => {
      return fetcher(url, {
        method: "POST",
        body: JSON.stringify(data),
      }) as Promise<Response>
    },
    [fetcher],
  )
}
