export enum DateDurations {
  LAST_24_HOURS = "Siste 24 timer",
  LAST_7_DAYS = "Siste 7 dager",
  LAST_MONTH = "Siste måned",
  LAST_YEAR = "Siste år",
  ALL_TIME = "All tid",
}

export function mapDateStringToDateEnum(
  dateDurationString: string,
): DateDurations {
  switch (dateDurationString) {
    case DateDurations.LAST_24_HOURS.toString():
      return DateDurations.LAST_24_HOURS
    case DateDurations.LAST_7_DAYS.toString():
      return DateDurations.LAST_7_DAYS
    case DateDurations.LAST_MONTH.toString():
      return DateDurations.LAST_MONTH
    case DateDurations.LAST_YEAR.toString():
      return DateDurations.LAST_YEAR
    case DateDurations.ALL_TIME.toString():
      return DateDurations.ALL_TIME
    default:
      throw Error(`Ugyldig tidstreng ${dateDurationString}`)
  }
}

export function trimTimestamp(timestamp: string): string {
  return timestamp.slice(0, 19)
}
