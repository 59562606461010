import { Heading, Card, Stack } from "@vygruppen/spor-react"
import { useEffect } from "react"
import { useGetActivityStats, useRegisterActivity } from "src/api/apiPaths"
import Spinner from "src/components/Spinner"

function ActivityPage() {
  const activityStats = useGetActivityStats()
  const registerActivity = useRegisterActivity()

  useEffect(() => {
    registerActivity("ACTIVITY_PAGE")
  }, [])

  const pageDistribution = activityStats?.pageDistribution.map((page) => {
    return (
      <Heading textStyle="md">
        {page.pageName.toLowerCase()}: {page.numberOfVisitors}
      </Heading>
    )
  })
  return (
    <Stack direction={["column", "row"]} my={[2, 2]} mx={[2, 8]} spacing="4">
      <Card variant="elevated" p={[2, 4]} minWidth="40%">
        <Heading>Antall aktive brukere</Heading>
        {activityStats ? (
          <>
            <Heading textStyle="md">
              Antall unike brukere totalt: {activityStats.numberOfDistinctUsers}
            </Heading>
            <Heading textStyle="md">
              Antall unike brukere siste måned:{" "}
              {activityStats.numberOfDistinctUsersLastMonth}
            </Heading>
            <Heading textStyle="md">
              Antall unike brukere siste uken:{" "}
              {activityStats.numberOfDistinctUsersLastWeek}
            </Heading>
          </>
        ) : (
          <Spinner />
        )}
      </Card>
      <Card variant="elevated" p={[2, 4]} minWidth="40%">
        <Heading>Sidefordeling</Heading>
        {activityStats ? <>{pageDistribution}</> : <Spinner />}
      </Card>
    </Stack>
  )
}

export default ActivityPage
