import { useEffect, useState } from "react"
import {
  Button,
  DownloadFill24Icon,
  FilterOutline30Icon,
  Flex,
  Text,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@vygruppen/spor-react"
import "react-datepicker/dist/react-datepicker.css"
import { SelectOption } from "src/types/FeedbackTypes"
import {
  useGetFeedbacks,
  useGetParameterOptions,
  useRegisterActivity,
} from "src/api/apiPaths"
import { useSearchParams } from "react-router-dom"
import FeedbackListFilters from "src/components/feedbackList/Filters"
import FeedbackList from "src/components/feedbackList/FeedbackList"
import { getAllTeamOptions } from "src/utils/feedbackUtils"
import { Box, Spacer, useDisclosure } from "@chakra-ui/react"
import { convertJsonToCsv } from "src/utils/jsonToCsv"
import { Card } from "src/components/layout/Card"

function FeedbackPage() {
  const [selectedQuestion, setSelectedQuestion] = useState<SelectOption>()
  const [selectedOrigin, setSelectedOrigin] = useState<SelectOption>()
  const [selectedTags, setSelectedTags] = useState<SelectOption[]>()
  const [selectedFeedbackValue, setSelectedFeedbackValue] =
    useState<SelectOption>()
  const [selectedTeamTag, setSelectedTeamTag] = useState<SelectOption>()
  const [startDate, setStartDate] = useState<Date | null>()
  const [endDate, setEndDate] = useState<Date | null>()
  const [mustHaveMessage, setMustHaveMessage] = useState(true)
  const [searchString, setSearchString] = useState<string>()
  const debouncedSearchString = useDebounce(searchString, 1000)
  const parameterOptions = useGetParameterOptions()
  const registerActivity = useRegisterActivity()
  const [searchParams, setSearchParams] = useSearchParams()

  const feedbackResponse = useGetFeedbacks({
    question: selectedQuestion?.value,
    originId: selectedOrigin?.value,
    ratingValue: selectedFeedbackValue?.value,
    tags: selectedTags?.map((option) => option.value!),
    startDate: startDate,
    endDate: endDate,
    searchString: debouncedSearchString,
    mustHaveMessage: mustHaveMessage,
    teamTag: selectedTeamTag?.value,
    feedbackLimit: 200,
  })
  useEffect(() => {
    registerActivity("FEEDBACK_LIST")
  }, [])

  const feedbackValuesOptions: SelectOption[] = [
    "HAPPY",
    "NEUTRAL",
    "UNHAPPY",
  ].map((value) => ({
    value: value,
    label: value,
  }))

  // Denne leser queryParams
  useEffect(() => {
    if (searchParams.get("question")) {
      setSelectedQuestion(
        parameterOptions?.questions?.find(
          (option) => option.value === searchParams.get("question")!,
        ),
      )
    }
    if (searchParams.get("origin")) {
      setSelectedOrigin(
        parameterOptions?.originIds?.find(
          (option) => option.value === searchParams.get("origin")!,
        ),
      )
    }
    if (searchParams.get("feedbackValue")) {
      setSelectedFeedbackValue(
        feedbackValuesOptions?.find(
          (option) => option.value === searchParams.get("feedbackValue")!,
        ),
      )
    }
    if (searchParams.get("team")) {
      setSelectedTeamTag(
        getAllTeamOptions().find(
          (option) => option.value === searchParams.get("team")!,
        ),
      )
    }
    if (searchParams.get("tags")) {
      const tags: string[] = searchParams.get("tags")?.split(",")!
      setSelectedTags(
        parameterOptions?.tagIds?.filter((option) =>
          tags.includes(option.value!),
        ),
      )
    }
    if (searchParams.get("startDate")) {
      const startDate = new Date(parseInt(searchParams.get("startDate")!))
      setStartDate(startDate)
    }
    if (searchParams.get("endDate")) {
      const endDate = new Date(parseInt(searchParams.get("endDate")!))
      setEndDate(endDate)
    }
    if (searchParams.get("mustHaveMessage")) {
      setMustHaveMessage(true)
    }
    if (searchParams.get("searchString")) {
      setSearchString(searchParams.get("searchString")!)
    }
  }, [parameterOptions])

  // Denne setter query params
  useEffect(() => {
    // Dersom parameterOptions ikke har lastet, har vi ikke ennå hatt muligheten til å lese inn parametere fra URLen
    if (!parameterOptions) {
      return
    }
    const params = new URLSearchParams()
    if (selectedQuestion?.value) {
      params.append("question", selectedQuestion.value)
    } else {
      params.delete("question")
    }
    if (selectedOrigin?.value) {
      params.append("origin", selectedOrigin.value)
    } else {
      params.delete("origin")
    }
    if (selectedTags && selectedTags.length > 0) {
      params.append(
        "tags",
        selectedTags.map((option) => option.value).join(","),
      )
    } else {
      params.delete("tags")
    }
    if (selectedFeedbackValue?.value) {
      params.append("feedbackValue", selectedFeedbackValue.value)
    } else {
      params.delete("feedbackValue")
    }
    if (selectedTeamTag?.value) {
      params.append("team", selectedTeamTag.value)
    } else {
      params.delete("team")
    }
    if (startDate) {
      params.append("startDate", startDate.getTime().toString())
    } else {
      params.delete("startDate")
    }
    if (endDate) {
      params.append("endDate", endDate.getTime().toString())
    } else {
      params.delete("endDate")
    }
    if (mustHaveMessage) {
      params.append("mustHaveMessage", "true")
    } else {
      params.delete("mustHaveMessage")
    }
    if (searchString) {
      params.append("searchString", searchString)
    } else {
      params.delete("searchString")
    }
    setSearchParams(params)
  }, [
    selectedQuestion,
    selectedOrigin,
    selectedFeedbackValue,
    selectedTags,
    startDate,
    endDate,
    mustHaveMessage,
    searchString,
    selectedTeamTag,
  ])

  const addTagToFilter = (tagId: string) => {
    const selectOptionToAdd = parameterOptions?.tagIds.find(
      (option) => option.value === tagId,
    )!
    setSelectedTags(
      Array.from(new Set([...(selectedTags ?? []), selectOptionToAdd])),
    )
  }

  const feedBackListFilters = (
    <FeedbackListFilters
      parameterOptions={parameterOptions}
      selectedQuestion={selectedQuestion}
      setSelectedQuestion={setSelectedQuestion}
      selectedOrigin={selectedOrigin}
      setSelectedOrigin={setSelectedOrigin}
      selectedTags={selectedTags}
      setSelectedTags={setSelectedTags}
      selectedFeedbackValue={selectedFeedbackValue}
      setSelectedFeedbackValue={setSelectedFeedbackValue}
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
      mustHaveMessage={mustHaveMessage}
      setMustHaveMessage={setMustHaveMessage}
      searchString={searchString}
      setSearchString={setSearchString}
      feedbacks={feedbackResponse?.feedbacks}
      feedbackValuesOptions={feedbackValuesOptions}
      teamTagOptions={getAllTeamOptions()}
      selectedTeamTag={selectedTeamTag}
      setSelectedTeamTag={setSelectedTeamTag}
    />
  )

  const { isOpen, onOpen, onClose } = useDisclosure()
  const helperText =
    feedbackResponse?.totalNumberOfMatchingFeedbacks &&
    feedbackResponse?.totalNumberOfMatchingFeedbacks > 200 ? (
      <Text mb={4}>Vi viser kun frem de 200 nyeste tilbakemeldingene.</Text>
    ) : null

  return (
    <Card>
      <Heading mb={0}>
        Tilbakemeldinger({feedbackResponse?.totalNumberOfMatchingFeedbacks})
      </Heading>
      {helperText}
      <Flex display={["flex", "none", "none"]}>
        <Spacer></Spacer>
        <Button
          aria-label="Endre filter"
          rightIcon={<FilterOutline30Icon />}
          variant="additional"
          onClick={onOpen}
          m={2}
        >
          Endre filter
        </Button>
        {feedbackResponse && (
          <Button
            leftIcon={<DownloadFill24Icon />}
            onClick={() => {
              convertJsonToCsv(feedbackResponse.feedbacks)
            }}
            m={2}
            colorScheme="teal"
          >
            <Text>Last ned </Text>
          </Button>
        )}

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent mx={[2, "auto"]}>
            <ModalBody p={5}>
              <Flex direction="column" gap={3}>
                {feedBackListFilters}
                <Button
                  onClick={onClose}
                  mx={6}
                  isLoading={feedbackResponse === undefined}
                >
                  Vis resultater ({feedbackResponse?.feedbacks.length})
                </Button>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Flex>

      <Flex gap={4}>
        <Flex
          direction="column"
          minWidth="275"
          maxWidth="275"
          display={["none", "flex", "flex"]}
          gap={2}
        >
          {feedBackListFilters}
          {feedbackResponse && (
            <Box alignSelf="center" mt={2}>
              <Button
                leftIcon={<DownloadFill24Icon />}
                onClick={() => {
                  convertJsonToCsv(feedbackResponse.feedbacks)
                }}
                minWidth={200}
                colorScheme="teal"
              >
                <Text>Last ned </Text>
              </Button>
            </Box>
          )}
        </Flex>
        <FeedbackList
          feedbackData={feedbackResponse?.feedbacks}
          addTagToFilter={addTagToFilter}
        />
      </Flex>
    </Card>
  )
}

export default FeedbackPage

function useDebounce<Type>(value: Type, delay: number): Type {
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)
    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])
  return debouncedValue
}
