import { Text, theme, Box } from "@vygruppen/spor-react"
import Select, { GroupBase, StylesConfig } from "react-select"
import { SelectOption } from "src/types/FeedbackTypes"

type TagsSelectorProps = {
  setSelectedTags: (selectedTags: SelectOption[]) => void
  selectedTags: SelectOption[] | undefined
  tags: SelectOption[]
}

export function TagsSelector({
  setSelectedTags,
  selectedTags,
  tags,
}: TagsSelectorProps) {
  const customStyles: StylesConfig<
    SelectOption,
    true,
    GroupBase<SelectOption>
  > = {
    option: (provided, state) => ({
      ...provided,
      background: state.isFocused ? theme.colors.alias.mint : "white",
      ":hover": {
        background: theme.colors.alias.mint,
      },
      ":focus": {
        background: theme.colors.alias.greenHaze,
      },
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: "12px",
      borderColor: state.isFocused ? theme.colors.alias.greenHaze : "black",
      boxShadow: state.isFocused
        ? `${theme.colors.alias.greenHaze} 0px 0px 0px 1px inset`
        : "0",
      ":hover": {
        borderColor: state.isFocused ? theme.colors.alias.greenHaze : "black",
        boxShadow: `${
          state.isFocused ? theme.colors.alias.greenHaze : "black"
        } 0px 0px 0px 1px inset`,
      },
      padding: "2px",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: "black",
      ":hover": {
        cursor: "pointer",
        color: theme.colors.alias.greenHaze,
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "black",
      ":hover": {
        cursor: "pointer",
        color: theme.colors.alias.greenHaze,
      },
    }),
    multiValue: (provided, state) => ({
      ...provided,
      border: "1px solid",
      borderRadius: "50px",
      borderColor: theme.colors.alias.greenHaze,
      background: theme.colors.alias.coralGreen,
      color: "black",
      padding: "2px 8px",
      margin: "4px 2px",
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      background: "transparent",
      ":hover": {
        color: theme.colors.alias.white,
      },
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      fontFamily: theme.fonts.body,
      fontSize: "16px",
      color: "black",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "black",
    }),
  }

  return (
    <Box>
      <Text textStyle="sm" mb={1}>
        Tags:
      </Text>
      <Select
        styles={customStyles}
        options={tags}
        isMulti
        value={selectedTags ?? []}
        onChange={(newValue) => setSelectedTags(newValue!.map((o) => o))}
        placeholder="Alle tags"
      />
    </Box>
  )
}
