import { Navigate, Route, Routes } from "react-router-dom"
import LoggedOutPage from "src/pages/LoggedOutPage"
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react"
import Header from "src/components/Header"
import FeedbackPage from "src/pages/FeedbackPage"
import ActivityPage from "src/pages/ActivityPage"
import { FeedbackDetails } from "./pages/FeedbackDetailPage"
import AppStoreReviewsPage from "./pages/AppStoreReviewsPage"
import PlayStoreReviewsPage from "./pages/PlayStoreReviewsPage"
import ChartsPage from "./pages/ChartsPage"

const App = () => {
  return (
    <>
      <UnauthenticatedTemplate>
        <LoggedOutPage />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Header />
        <Routes>
          <Route path="/activity" element={<ActivityPage />} />
          <Route path="/feedback/:feedbackId" element={<FeedbackDetails />} />
          <Route path="/feedback" element={<FeedbackPage />} />
          <Route path="/app-store-reviews" element={<AppStoreReviewsPage />} />
          <Route path="/charts" element={<ChartsPage />} />
          <Route
            path="/play-store-reviews"
            element={<PlayStoreReviewsPage />}
          />

          <Route
            path="/"
            element={<Navigate to="/feedback" replace={true} />}
          />
        </Routes>
      </AuthenticatedTemplate>
    </>
  )
}
export default App
