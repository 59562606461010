import { useMsal } from "@azure/msal-react"
import { useCallback } from "react"
import { loginRequest } from "src/auth/config"

export function useGetAccessToken() {
  const { instance, accounts } = useMsal()

  return useCallback(async () => {
    try {
      const res = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      return res.accessToken
    } catch (error) {
      console.error(error)
      instance.logoutRedirect({
        postLogoutRedirectUri: window.location.origin,
      })
    }
  }, [instance, accounts])
}
