import { useAuth0 } from "src/auth/useAuth-compat"
import { Box, Heading, theme, Button } from "@vygruppen/spor-react"

const LoggedOutPage = () => {
  const { loginWithRedirect } = useAuth0()

  return (
    <Box
      backgroundColor={theme.colors.alias.platinum}
      padding="100"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      height="100vh"
    >
      <Box
        padding="50"
        justifyContent="center"
        alignItems="center"
        display="flex"
        flexDirection="column"
      >
        <Heading>Kundepuls</Heading>
        <Button variant="primary" marginTop="30" onClick={loginWithRedirect}>
          Logg inn
        </Button>
      </Box>
    </Box>
  )
}
export default LoggedOutPage
