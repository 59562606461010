import {
  Button,
  CopyOutline18Icon,
  CopyOutline24Icon,
  IconButton,
  SuccessOutline18Icon,
  SuccessOutline24Icon,
} from "@vygruppen/spor-react"
import { Feedback } from "src/types/FeedbackTypes"
import { getSlackMessage } from "src/utils/feedbackUtils"
import { useCallback, useEffect, useState } from "react"

type CopyFeedbackButtonProps = {
  feedback?: Feedback
  iconOnly?: boolean
}

function CopyFeedbackButton({ feedback, iconOnly }: CopyFeedbackButtonProps) {
  const message = feedback ? getSlackMessage(feedback) : ""
  const { onCopy, hasCopied } = useClipboard(message)

  if (iconOnly) {
    return (
      <IconButton
        icon={hasCopied ? <SuccessOutline18Icon /> : <CopyOutline18Icon />}
        variant="ghost"
        size="sm"
        aria-label="Kopier"
        title="Kopier"
        onClick={onCopy}
      />
    )
  }

  return (
    <Button
      leftIcon={hasCopied ? <SuccessOutline24Icon /> : <CopyOutline24Icon />}
      variant="tertiary"
      aria-label="Kopier"
      onClick={onCopy}
    >
      {hasCopied ? "Kopiert!" : "Kopier"}
    </Button>
  )
}

export default CopyFeedbackButton

function useClipboard(message: string): {
  onCopy: () => void
  hasCopied: boolean
} {
  const [hasCopied, setHasCopied] = useState(false)
  const timeout = 2000

  const onCopy = useCallback(() => {
    navigator.clipboard.writeText(message)
    setHasCopied(true)
  }, [message])

  useEffect(() => {
    let timeoutId: number | null = null

    if (hasCopied) {
      timeoutId = window.setTimeout(() => {
        setHasCopied(false)
      }, timeout)
    }

    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId)
      }
    }
  }, [hasCopied])

  return { onCopy, hasCopied }
}
