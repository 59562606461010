import {
  Badge,
  Box,
  Flex,
  Text,
  Wrap,
  SimplePopover,
  Card,
  HStack,
  Spacer,
  Heading,
} from "@vygruppen/spor-react"
import { useDisclosure } from "@chakra-ui/react"
import "react-datepicker/dist/react-datepicker.css"
import { FeedbackWithTagsAndTeam } from "src/types/FeedbackTypes"
import { FeedbackIdLink } from "src/components/links/FeedbackIdLink"
import { trimTimestamp } from "src/utils/timeUtils"
import Spinner from "../Spinner"
import TeamTagModal from "./TeamTagModal"
import { useState } from "react"

type FeedbackTableProps = {
  feedbackData?: FeedbackWithTagsAndTeam[]
  addTagToFilter: (tagId: string) => void
}

function FeedbackList(props: FeedbackTableProps) {
  const { feedbackData, addTagToFilter } = props

  const [selectedFeedback, setSelectedFeedback] =
    useState<FeedbackWithTagsAndTeam>()
  const { isOpen, onOpen, onClose } = useDisclosure()

  function TeamTag(item: FeedbackWithTagsAndTeam) {
    return (
      <SimplePopover
        triggerElement={
          <Badge
            cursor="pointer"
            variant="solid"
            colorScheme={item.teamTag ? "green" : "grey"}
            onClick={() => {
              setSelectedFeedback(item)
              onOpen()
            }}
          >
            {item.teamTag ?? "Mangler team"}
          </Badge>
        }
        trigger="hover"
        size="sm"
      >
        Endre team
      </SimplePopover>
    )
  }

  if (feedbackData) {
    return (
      <>
        <Flex direction="column" gap={3} grow={1} minWidth="10%">
          {feedbackData.map((item) => (
            <Card variant="elevated" key={item.id}>
              <Flex direction="column">
                <Box
                  height={1}
                  backgroundColor={getFeedbackColor(item.ratingValue)}
                />

                <Flex
                  direction="column"
                  alignItems="start"
                  gap={1}
                  p={2}
                  grow={1}
                >
                  <Heading textStyle="md">{item.question}</Heading>
                  <Text textStyle="sm" overflowWrap="anywhere">
                    {item.message}
                  </Text>
                </Flex>
              </Flex>
              {!item?.tags ||
                (item?.tags?.length > 0 && (
                  <Wrap
                    spacing={1}
                    backgroundColor="alias.lightBlue"
                    padding="2"
                  >
                    <Text>Tags:</Text>
                    {item.tags?.map((tag) => (
                      <SimplePopover
                        key={tag.tagId}
                        trigger="hover"
                        triggerElement={
                          <Badge
                            cursor="pointer"
                            onClick={() => addTagToFilter(tag.tagId)}
                          >
                            {tag.name}
                          </Badge>
                        }
                      >
                        {`Legg til "${tag.name}" i filter`}
                      </SimplePopover>
                    ))}
                  </Wrap>
                ))}

              <Flex
                direction="row"
                alignItems="center"
                wrap="wrap"
                backgroundColor="alias.lightGrey"
                gap={3}
                p={2}
              >
                <Flex direction="column">
                  <HStack>
                    <Text>Opprinnelse:</Text>
                    <Text fontWeight="bold">{item.originId}</Text>
                  </HStack>
                  <HStack marginTop={2} marginBottom={2}>
                    <Badge colorScheme="green" wordBreak="keep-all">
                      {trimTimestamp(item.timestamp)}
                    </Badge>
                    <Badge colorScheme="yellow" wordBreak="keep-all">
                      {item.userId ? "Innlogget" : "Ikke innlogget"}
                    </Badge>
                  </HStack>
                  <HStack>
                    <Text>Team:</Text>
                    {TeamTag(item)}
                  </HStack>
                </Flex>
                <Spacer />
                <FeedbackIdLink feedbackId={item.id} />
              </Flex>
            </Card>
          ))}
        </Flex>
        <TeamTagModal
          onClose={onClose}
          isOpen={isOpen}
          feedback={selectedFeedback}
        />
      </>
    )
  } else return <Spinner label="Laster tilbakemeldinger" />
}

function getFeedbackColor(value: string): string {
  switch (value) {
    case "HAPPY":
      return "alias.blueGreen"
    case "NEUTRAL":
      return "alias.sunshine"
    case "UNHAPPY":
      return "alias.lightRed"
    default:
      return ""
  }
}

export default FeedbackList
