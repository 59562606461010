import {
  FrownFill24Icon,
  NeutralFill24Icon,
  SmileFill24Icon,
} from "@vygruppen/spor-react"
import { FeedbackWithTagsAndTeam, SelectOption } from "src/types/FeedbackTypes"

export function getEmoji(rating: string) {
  switch (rating) {
    case "HAPPY":
      return <SmileFill24Icon />
    case "UNHAPPY":
      return <FrownFill24Icon />
    case "NEUTRAL":
      return <NeutralFill24Icon />
    default:
      return <></>
  }
}

export function getFeedbackLink(feedbackId: string) {
  return `${window.location.protocol}//${window.location.host}${window.location.pathname}#/feedback/${feedbackId}`
}

export function getSlackMessage(feedback: FeedbackWithTagsAndTeam) {
  const where = `Hvor: ${feedback.originId}\n`
  const mood = `Humør: ${feedback.ratingValue}\n`
  const appVersion = feedback.appVersion
    ? `\nAppversjon: ${feedback.appVersion}`
    : ""
  const platform = feedback.platform ? `\nPlattform: ${feedback.platform}` : ""
  const message = feedback.message
    ? `\n-----\n${feedback.message}\n-----\n`
    : ""
  const tags = `Tags: ${feedback.tags
    ?.filter((x) => !!x) // filter away null values, which happens every so often when tag does not have a name
    .map((tag) => tag.name)
    .join(", ")}\n`
  const feedbackLink = `\nLink: ${getFeedbackLink(feedback.id)}`

  return `${where}${tags}${mood}${message}${appVersion}${platform}${feedbackLink}`
}

export function getCurrentTeamOptions() {
  const teamTagOptions: SelectOption[] = [
    "Kundeinnsikt",
    "Nye tjenester",
    "Kanaler",
    "Reise",
    "Kjøp",
    "Kunde",
    "Basistjenester",
    "App koordineringsgruppe",
    "Designsystem",
    "Mangler team",
  ].map((value) => ({
    value: value,
    label: value,
  }))
  return teamTagOptions
}

export function getAllTeamOptions() {
  const current: SelectOption[] = getCurrentTeamOptions()
  const other: SelectOption[] = [
    "Sone - Periodebillett",
    "Reiseinfo",
    "Enkeltreise",
    "Brukeropplevelse app",
    "WEB + innhold",
    "Fellestjenester",
    "Reiseinfo",
    "Kjøpsløp",
  ].map((value) => ({
    value: value,
    label: value,
  }))

  return [...current, ...other]
}

export const mapRatingToStars = (rating: number): string => {
  const starIcon = "⭐️"
  return starIcon.repeat(rating)
}
