import {
  Box,
  Heading,
  Text,
  Badge,
  Card,
  Radio,
  RadioGroup,
} from "@vygruppen/spor-react"
import { useEffect, useState } from "react"

import { useGetAppStoreReviews, useRegisterActivity } from "src/api/apiPaths"
import Spinner from "src/components/Spinner"
import { mapRatingToStars } from "src/utils/feedbackUtils"

function AppStoreReviewsPage() {
  const [reviewFilterValue, setReviewFilterValue] = useState("0")
  const appStoreReviews = useGetAppStoreReviews(reviewFilterValue)
  const registerActivity = useRegisterActivity()

  useEffect(() => {
    registerActivity("APP_STORE_PAGE")
  }, [])

  const appStoreReviewCards = appStoreReviews?.appStoreReviews.map(
    (appReview) => {
      return (
        <Card
          variant="elevated"
          my={[2, 4]}
          mx={[2, 8]}
          p={[2, 4]}
          key={appReview.id}
        >
          <Heading textStyle="md">{appReview.title}</Heading>
          <Text>{mapRatingToStars(appReview.rating)}</Text>
          <Text>{appReview.body}</Text>
          <Box paddingTop={2}>
            <Badge colorScheme="green">{appReview.createdDate}</Badge>
            <Badge colorScheme="yellow" marginLeft={2}>
              {appReview.territory}
            </Badge>
          </Box>
        </Card>
      )
    },
  )

  return (
    <>
      <Box mx={[4, 8]}>
        <Heading>App store reviews</Heading>
        <Text>Tilbakemeldinger fra app store (Apple)</Text>
        <Box marginTop={2} overflow="scroll" whiteSpace="nowrap">
          <RadioGroup
            name="transport"
            value={reviewFilterValue}
            onChange={setReviewFilterValue}
          >
            <Radio value="0">Alle</Radio>
            <Radio value="5">5 ⭐️</Radio>
            <Radio value="4">4 ⭐️</Radio>
            <Radio value="3">3 ⭐️</Radio>
            <Radio value="2">2 ⭐️</Radio>
            <Radio value="1">1 ⭐️</Radio>
          </RadioGroup>
        </Box>
      </Box>
      {appStoreReviews ? appStoreReviewCards : <Spinner />}
    </>
  )
}

export default AppStoreReviewsPage
