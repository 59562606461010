import { useEffect } from "react"
import {
  Box,
  Button,
  Heading,
  Table,
  Tbody,
  Td,
  Th,
  Tr,
  HStack,
  EditOutline24Icon,
} from "@vygruppen/spor-react"
import { useParams } from "react-router-dom"
import { useGetFeedback, useRegisterActivity } from "src/api/apiPaths"
import EditFeedbackModal from "src/components/details/EditFeedbackModal"
import Spinner from "src/components/Spinner"
import { Card } from "src/components/layout/Card"
import { useDisclosure } from "@chakra-ui/react"
import CopyFeedbackButton from "src/components/CopyFeedbackButton"
import { FeedbackCustomerSegments } from "src/types/FeedbackTypes"

type FeedbackDetailsParams = {
  feedbackId: string
}

export const FeedbackDetails = () => {
  const { feedbackId } = useParams<FeedbackDetailsParams>()
  const { data: feedback, mutate } = useGetFeedback(feedbackId ?? "")
  const registerActivity = useRegisterActivity()
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    registerActivity("DETAIL_PAGE")
  }, [])

  return (
    <Card>
      {feedback ? (
        <>
          <Heading size="md" padding="6">
            Tilbakemeldingsdata
          </Heading>
          <Table>
            <Tbody>
              <Tr>
                <Th>Id</Th>
                <Td>{feedback.id}</Td>
              </Tr>
              <Tr>
                <Th>BrukerId</Th>
                <Td>{feedback.userId}</Td>
              </Tr>
              <Tr>
                <Th>Opprinnelse</Th>
                <Td>{feedback.originId}</Td>
              </Tr>
              <Tr>
                <Th>Spørsmål</Th>
                <Td>{feedback.question}</Td>
              </Tr>
              <Tr>
                <Th>Tilbakemeldingsverdi</Th>
                <Td>{feedback.ratingValue}</Td>
              </Tr>
              <Tr>
                <Th>Melding</Th>
                <Td>
                  <HStack justifyContent="space-between">
                    <Box>{feedback.message}</Box>
                    <Button
                      leftIcon={<EditOutline24Icon />}
                      onClick={onOpen}
                      colorScheme="teal"
                    >
                      Rediger
                    </Button>
                  </HStack>
                </Td>
              </Tr>
              <Tr>
                <Th>Tidspunkt</Th>
                <Td>{feedback.timestamp}</Td>
              </Tr>
              <Tr>
                <Th>Tags</Th>
                <Td>{feedback.tags?.join(", ")}</Td>
              </Tr>
              <MaybeRow label="Appversjon" value={feedback.appVersion} />
              <MaybeRow label="Plattform" value={feedback.platform} />
              <MaybeRow
                label="Plattform Versjon"
                value={feedback.platformVersion}
              />
              <MaybeRow
                label="Kundesegment"
                value={mapCustomerSegmentsToString(
                  feedback.feedbackCustomerSegments,
                )}
              />
              <MaybeRow label="Kjøpsflyt" value={feedback.purchaseFlow} />
              <MaybeRow label="Type reise" value={feedback.typeOfJourney} />
              <MaybeRow label="Språk" value={feedback.language} />
              <MaybeRow label="Opphavssti" value={feedback.originPath} />
            </Tbody>
          </Table>
          <Box padding={5}>
            <CopyFeedbackButton feedback={feedback} />
          </Box>
          <EditFeedbackModal
            feedback={feedback}
            isOpen={isOpen}
            onClose={onClose}
            mutate={mutate}
          />
        </>
      ) : (
        <Spinner />
      )}
    </Card>
  )
}

function mapCustomerSegmentsToString(
  customerSegments?: FeedbackCustomerSegments,
): string | undefined {
  if (!customerSegments) {
    return undefined
  }

  let customerSegmentsArray: string[] = []
  if (customerSegments.isPeriodebillettpendler) {
    customerSegmentsArray.push("Periodebillettpendler")
  }
  if (customerSegments.isAvOgTilReisende) {
    customerSegmentsArray.push("Av-og-til-reisende")
  }
  if (customerSegments.isKollektivByreisende) {
    customerSegmentsArray.push("Kollektivbyreisende")
  }
  if (customerSegments.isLangturreisende) {
    customerSegmentsArray.push("Langturreisende")
  }
  if (customerSegments.isOftereisende) {
    customerSegmentsArray.push("Oftereisende")
  }
  if (customerSegments.isSmartprispendler) {
    customerSegmentsArray.push("Smartprispendler")
  }

  return customerSegmentsArray.join(", ")
}

function MaybeRow(props: { label: string; value?: string }) {
  if (!props.value) return null

  return (
    <Tr>
      <Th>{props.label}</Th>
      <Td>{props.value}</Td>
    </Tr>
  )
}
