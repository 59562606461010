import { Box, theme } from "@vygruppen/spor-react"
import { ReactNode } from "react"

type Props = {
  bg?: string
  children: ReactNode
}

export function Card({ bg = theme.colors.alias.white, children }: Props) {
  return (
    <Box
      bg={bg}
      borderRadius={[0, "xl"]}
      backgroundColor="white"
      my={[4, 8]}
      mx={[0, 6, 8]}
      p={[3, 8]}
    >
      {children}
    </Box>
  )
}
