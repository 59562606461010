import { Heading, Card, Text } from "@vygruppen/spor-react"
import { useEffect } from "react"
import { useRegisterActivity } from "src/api/apiPaths"

function ChartsPage() {
  const registerActivity = useRegisterActivity()

  useEffect(() => {
    registerActivity("FAKE_GRAPHS_PAGE")
  }, [])

  return (
    <Card variant="elevated" my={[2, 4]} mx={[2, 8]} p={[2, 4]}>
      <Heading>Grafer</Heading>
      <Text> 🚧 Under arbeid 🚧 </Text>
    </Card>
  )
}

export default ChartsPage
