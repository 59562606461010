import {
  Button,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@vygruppen/spor-react"
import { useCallback, useState } from "react"
import { BASE_URL } from "src/api/base-url"
import { usePostRequest } from "src/api/useSuspenseFetch"
import { Feedback } from "src/types/FeedbackTypes"

type FeedbackDetailsParams = {
  feedback: Feedback
  isOpen: boolean
  onClose: () => void
  mutate: () => void
}

const EditFeedbackModal = (props: FeedbackDetailsParams) => {
  const [editedfeedbackMessage, setEditedFeedbackMessage] = useState(
    props.feedback.message,
  )

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditedFeedbackMessage(e.target.value)
  }

  const editMessage = useEditMessage()

  function handleSaveButtonPressed(feedbackId: string, message: string) {
    editMessage(feedbackId, message)
    setTimeout(() => {
      props.mutate()
    }, 1000)
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Rediger tilbakemelding</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Textarea
            label=""
            value={editedfeedbackMessage}
            onChange={handleInputChange}
            size="sm"
          />
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={props.onClose}>
            Lukk
          </Button>
          <Button
            mr={3}
            onClick={() => {
              handleSaveButtonPressed(props.feedback.id, editedfeedbackMessage)
              props.onClose()
            }}
          >
            Lagre
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

function useEditMessage() {
  const post = usePostRequest()

  return useCallback(
    async (feedbackId: string, message: string) => {
      return post(BASE_URL + `/feedback/edit/${feedbackId}`, {
        feedbackId: feedbackId,
        message: message,
      })
    },
    [post],
  )
}

export default EditFeedbackModal
