import { Checkbox, SearchInput, Input, Skeleton } from "@vygruppen/spor-react"
import "react-datepicker/dist/react-datepicker.css"
import {
  FeedbackWithTagsAndTeam,
  ParameterOptions,
  SelectOption,
} from "src/types/FeedbackTypes"
import { FilterDropdown } from "./FilterDropdown"
import { TagsSelector } from "./TagsSelector"

type FeedbackListFiltersProps = {
  parameterOptions?: ParameterOptions
  selectedQuestion?: SelectOption
  setSelectedQuestion: (selectedQuestion: SelectOption) => void
  selectedOrigin?: SelectOption
  setSelectedOrigin: (selectedOrigin: SelectOption) => void
  selectedTags?: SelectOption[]
  setSelectedTags: (selectedTags: SelectOption[]) => void
  selectedFeedbackValue?: SelectOption
  setSelectedFeedbackValue: (selectedFeedbackValue: SelectOption) => void
  startDate?: Date | null
  setStartDate: (date: Date | null) => void
  endDate?: Date | null
  setEndDate: (date: Date | null) => void
  mustHaveMessage?: boolean
  setMustHaveMessage: (mustHaveMessage: boolean) => void
  searchString?: string
  setSearchString: (searchString: string) => void
  feedbacks?: FeedbackWithTagsAndTeam[]
  feedbackValuesOptions: SelectOption[]
  teamTagOptions: SelectOption[]
  selectedTeamTag?: SelectOption
  setSelectedTeamTag: (selectedTeamTag: SelectOption) => void
}

function FeedbackListFilters(props: FeedbackListFiltersProps) {
  const {
    parameterOptions,
    selectedQuestion,
    setSelectedQuestion,
    selectedOrigin,
    setSelectedOrigin,
    selectedTags,
    setSelectedTags,
    selectedFeedbackValue,
    setSelectedFeedbackValue,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    mustHaveMessage,
    setMustHaveMessage,
    searchString,
    setSearchString,
    feedbacks,
    feedbackValuesOptions,
    teamTagOptions,
    selectedTeamTag,
    setSelectedTeamTag,
  } = props

  return (
    <>
      <SearchInput
        size="lg"
        onChange={(e) => setSearchString(e.currentTarget.value)}
        value={searchString}
      />

      <Input
        type="date"
        label="Velg startdato"
        value={startDate?.toISOString().split("T")[0]}
        onChange={(event) => setStartDate(event.target.valueAsDate)}
      />

      <Input
        type="date"
        label="Velg sluttdato"
        value={endDate?.toISOString().split("T")[0]}
        onChange={(event) => setEndDate(event.target.valueAsDate)}
      />

      {parameterOptions?.questions ? (
        <FilterDropdown
          label="Spørsmål"
          defaultOption="Alle spørsmål"
          options={parameterOptions.questions}
          selected={selectedQuestion}
          setSelected={setSelectedQuestion}
        />
      ) : (
        <Skeleton height={8} />
      )}

      {parameterOptions?.originIds ? (
        <FilterDropdown
          label="Opphav"
          defaultOption="Alle opphav"
          options={parameterOptions.originIds}
          selected={selectedOrigin}
          setSelected={setSelectedOrigin}
        />
      ) : (
        <Skeleton height={8} />
      )}

      <FilterDropdown
        label="Tilbakemeldingsverdi"
        defaultOption="Alle verdier"
        options={feedbackValuesOptions}
        selected={selectedFeedbackValue}
        setSelected={setSelectedFeedbackValue}
      />

      <FilterDropdown
        label="Team"
        defaultOption="Alle team"
        options={teamTagOptions}
        selected={selectedTeamTag}
        setSelected={(newTeamTag) => setSelectedTeamTag(newTeamTag!)}
      />

      {parameterOptions?.tagIds ? (
        <TagsSelector
          setSelectedTags={setSelectedTags}
          selectedTags={selectedTags}
          tags={parameterOptions.tagIds}
        />
      ) : (
        <Skeleton height={8} />
      )}

      <Checkbox
        alignSelf={"flex-start"}
        paddingLeft={4}
        isChecked={mustHaveMessage}
        onChange={(e) => setMustHaveMessage(e.target.checked)}
      >
        Må ha med melding
      </Checkbox>
    </>
  )
}

export default FeedbackListFilters
