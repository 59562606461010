import format from "date-fns/format"
import {
  useGetUser,
  usePostRequest,
  useSuspenseFetch,
} from "src/api/useSuspenseFetch"
import { BASE_URL } from "src/api/base-url"
import {
  ActivityStats,
  FeedbackListReponse,
  FeedbackWithTagsAndTeam,
  ParameterOptions,
  AppStoreReviewsResponse,
  PlayStoreReviewsResponse,
} from "src/types/FeedbackTypes"
import { useCallback } from "react"

export type UseGetFeedbacksProps = {
  question?: string
  originId?: string
  ratingValue?: string
  tags?: string[]
  startDate?: Date | null
  endDate?: Date | null
  searchString?: string
  mustHaveMessage?: boolean
  teamTag?: string
  feedbackLimit?: number
}

export function useGetFeedbacks(props: UseGetFeedbacksProps) {
  const url = new URL(`${BASE_URL}/feedback`)

  if (props.question) {
    url.searchParams.append("question", props.question)
  }
  if (props.originId) {
    url.searchParams.append("originId", props.originId)
  }
  if (props.ratingValue) {
    url.searchParams.append("ratingValue", props.ratingValue)
  }
  if (props.tags && props.tags.length > 0) {
    url.searchParams.append("tags", JSON.stringify(props.tags))
  }
  if (props.startDate) {
    url.searchParams.append("startDate", format(props.startDate, "yyyy-MM-dd"))
  }
  if (props.endDate) {
    url.searchParams.append("endDate", format(props.endDate, "yyyy-MM-dd"))
  }
  if (props.searchString) {
    url.searchParams.append("searchString", props.searchString)
  }
  if (props.mustHaveMessage) {
    url.searchParams.append("mustHaveMessage", props.mustHaveMessage.toString())
  }
  if (props.teamTag) {
    url.searchParams.append("teamTag", props.teamTag.toString())
  }
  if (props.feedbackLimit) {
    url.searchParams.append("feedbackLimit", props.feedbackLimit.toString())
  }

  return useSuspenseFetch<FeedbackListReponse>(url.toString()).data
}

export function useGetParameterOptions() {
  const url = new URL(`${BASE_URL}/parameter_options`)

  return useSuspenseFetch<ParameterOptions>(url.toString()).data
}

export function useGetActivityStats() {
  const url = new URL(`${BASE_URL}/activity/stats`)

  return useSuspenseFetch<ActivityStats>(url.toString()).data
}

export function useGetAppStoreReviews(reviewFilterValue: string) {
  const url = new URL(`${BASE_URL}/app-store-reviews`)
  url.searchParams.append("reviewFilterValue", reviewFilterValue)
  return useSuspenseFetch<AppStoreReviewsResponse>(url.toString()).data
}

export function useGetPlayStoreReviews(reviewFilterValue: string) {
  const url = new URL(`${BASE_URL}/play-store-reviews`)
  url.searchParams.append("reviewFilterValue", reviewFilterValue)
  return useSuspenseFetch<PlayStoreReviewsResponse>(url.toString()).data
}

export function useGetFeedback(feedbackId: string) {
  const url = new URL(`${BASE_URL}/feedback/${feedbackId}`)
  return useSuspenseFetch<FeedbackWithTagsAndTeam>(url.toString())
}

export function useRegisterActivity() {
  const url = new URL(`${BASE_URL}/activity`).toString()
  const post = usePostRequest()
  const user = useGetUser()

  return useCallback(
    async (origin: string) => {
      const timestamp = new Date()
      return post(url, {
        userName: user,
        origin: origin,
        timestamp: timestamp.toUTCString(),
      })
    },
    [post],
  )
}
