import { useMsal } from "@azure/msal-react"
import { useCallback, useMemo } from "react"
import { loginRequest } from "src/auth/config"

/**
 * Dette er en midlertidig hack for å slippe å endre alle stedene vi kaller backend i samme PR.
 */
export function useAuth0() {
  const { instance, accounts } = useMsal()

  const getAccessTokenSilently = useCallback(async () => {
    const res = await instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    })
    return res.accessToken
  }, [instance, accounts])

  const loginWithRedirect = useCallback(() => {
    instance.loginRedirect(loginRequest)
  }, [instance])

  const logout = useCallback(
    async ({ returnTo }: { returnTo: string }) =>
      instance.logoutRedirect({
        postLogoutRedirectUri: returnTo,
      }),
    [instance],
  )

  const user = useMemo(() => {
    return { email: accounts[0]?.username }
  }, [accounts])

  return {
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
    user,
  }
}
