import { useDisclosure } from "@chakra-ui/react"
import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Button,
  Divider,
  HamburgerFill24Icon,
  IconButton,
  Spacer,
  Stack,
} from "@vygruppen/spor-react"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { useAuth0 } from "src/auth/useAuth-compat"
import NavigationLink from "./NavigationLink"

export type MenuItem = {
  to: string
  label: string
}

export type NavigationProps = {
  menuItems: MenuItem[]
}

export default function Menu({ menuItems }: NavigationProps) {
  return (
    <>
      <Flex flexGrow={1} display={["none", "flex", "flex"]}>
        <DesktopMenu menuItems={menuItems} />
      </Flex>
      <Flex flexGrow={1} display={["flex", "none", "none"]}>
        <MobileMenu menuItems={menuItems} />
      </Flex>
    </>
  )
}

function DesktopMenu({ menuItems }: NavigationProps) {
  const { logout } = useAuth0()
  return (
    <>
      <Stack direction="row">
        {menuItems?.map((item) => (
          <NavigationLink key={item.to} to={item.to}>
            {item.label}
          </NavigationLink>
        ))}
      </Stack>
      <Spacer />
      <Button
        variant="ghost"
        borderRadius="sm"
        onClick={() => logout({ returnTo: window.location.origin })}
      >
        Logg ut
      </Button>
    </>
  )
}

function MobileMenu({ menuItems }: NavigationProps) {
  const { logout } = useAuth0()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const location = useLocation()
  useEffect(() => {
    onClose()
  }, [location.pathname])

  return (
    <>
      <Spacer />
      <IconButton
        icon={<HamburgerFill24Icon />}
        aria-label="Meny"
        variant="ghost"
        onClick={onOpen}
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent mx={[2, "auto"]}>
          <ModalBody py={2} px={[1, 2, 3]}>
            <Stack spacing={2}>
              {menuItems?.map((item) => (
                <NavigationLink key={item.to} to={item.to}>
                  {item.label}
                </NavigationLink>
              ))}
              <Divider />
              <Button
                variant="ghost"
                borderRadius="sm"
                onClick={() => logout({ returnTo: window.location.origin })}
              >
                Logg ut
              </Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
