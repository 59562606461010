import { Select } from "@vygruppen/spor-react"
import { SelectOption } from "src/types/FeedbackTypes"

type FilterDropdownProps = {
  options: SelectOption[]
  selected?: SelectOption
  setSelected: (selectedOption: SelectOption) => void
  label?: string
  defaultOption?: string
}

export function FilterDropdown({
  label,
  defaultOption,
  options,
  selected,
  setSelected,
}: FilterDropdownProps) {
  return (
    <Select
      label={label}
      value={selected?.label}
      onChange={(event) =>
        setSelected(options.find((opt) => opt.value === event.target.value)!)
      }
    >
      <option value={undefined}>{defaultOption}</option>
      {options.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </Select>
  )
}
