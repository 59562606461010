import {
  BrowserCacheLocation,
  Configuration,
  LogLevel,
} from "@azure/msal-browser"

export const msalConfig: Configuration = {
  auth: {
    clientId: "d8c8043c-e4c8-43df-ba94-82454c8b2127",
    authority: "https://login.microsoftonline.com/nsbas.onmicrosoft.com",
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean,
      ) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            console.info(message)
            return
          case LogLevel.Verbose:
            console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            break
          default:
            break
        }
      },
    },
  },
}

export const loginRequest = {
  scopes: [
    "api://d8c8043c-e4c8-43df-ba94-82454c8b2127/tilbakemeldingsentralen.admin",
  ],
}
