import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@vygruppen/spor-react"
import { useCallback, useState } from "react"
import Select from "react-select"
import { BASE_URL } from "src/api/base-url"
import { usePostRequest } from "src/api/useSuspenseFetch"
import { FeedbackWithTagsAndTeam, SelectOption } from "src/types/FeedbackTypes"
import { getCurrentTeamOptions } from "src/utils/feedbackUtils"

type TeamTagModalParams = {
  feedback?: FeedbackWithTagsAndTeam
  isOpen: boolean
  onClose: () => void
}

const TeamTagModal = (props: TeamTagModalParams) => {
  const [selectedTeam, setSelectedTeam] = useState<SelectOption | null>()
  const setTeamTag = useSetTeamTag()

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Velg team</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Select
            options={getCurrentTeamOptions()}
            value={selectedTeam}
            onChange={(newTeamTag) => setSelectedTeam(newTeamTag)}
            placeholder="Velg team"
          />
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={props.onClose}>
            Lukk
          </Button>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => {
              if (props.feedback?.id && selectedTeam?.label) {
                setTeamTag(props.feedback?.id, selectedTeam.label)
                props.onClose()
              }
            }}
          >
            Lagre
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

function useSetTeamTag() {
  const post = usePostRequest()

  return useCallback(
    async (feedbackId: string, teamTag: string) => {
      return post(BASE_URL + `/feedback/${feedbackId}/team_tag`, {
        feedbackId: feedbackId,
        teamTag: teamTag,
      })
    },
    [post],
  )
}

export default TeamTagModal
