import { Center } from "@vygruppen/spor-react"
import { Link, useMatch, useResolvedPath } from "react-router-dom"

type NavLinkProps = {
  to: string
  children: React.ReactNode
}

function NavigationLink({ to, children }: NavLinkProps) {
  const resolved = useResolvedPath(to)
  const isActive = useMatch({ path: resolved.pathname, end: false })

  return (
    <Center
      as={Link}
      to={to}
      height="42px"
      px={3}
      borderRadius="sm"
      borderWidth="1"
      border="solid transparent"
      whiteSpace="nowrap"
      fontWeight="bold"
      fontStyle="sm"
      _focusVisible={{ borderColor: "alias.coralGreen", outline: "none" }}
      _hover={{ backgroundColor: "alias.coralGreen" }}
      _active={{
        borderColor: "alias.greenHaze",
      }}
      backgroundColor={isActive ? "alias.coralGreen" : "transparent"}
      transitionDuration="fast"
      transitionProperty="common"
    >
      {children}
    </Center>
  )
}

export default NavigationLink
