import React from "react"
import { createRoot } from "react-dom/client"
import App from "src/App"
import { HashRouter as Router } from "react-router-dom"
import { PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import { msalConfig } from "src/auth/config"
import { Language, SporProvider } from "@vygruppen/spor-react"
import theme from "src/theme"

const msalInstance = new PublicClientApplication(msalConfig)

const container = document.getElementById("root")
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <Router>
      <SporProvider language={Language.NorwegianBokmal} theme={theme}>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </SporProvider>
    </Router>
  </React.StrictMode>,
)
