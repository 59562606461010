import { Link as ReactRouterLink } from "react-router-dom"
import { ArrowRightOutline30Icon, TextLink } from "@vygruppen/spor-react"

type Props = {
  feedbackId: string
}

export function FeedbackIdLink({ feedbackId }: Props) {
  return (
    <TextLink as={ReactRouterLink} to={`/feedback/${feedbackId}`}>
      Se detaljer
      <ArrowRightOutline30Icon />
    </TextLink>
  )
}
