import { extendTheme, theme as BaseTheme } from "@vygruppen/spor-react"

const theme = extendTheme(
  {
    styles: {
      global: {
        "html, body": { background: BaseTheme.colors.alias.lightGrey },
        ".chakra-collapse": {
          overflow: "initial !important",
        },
      },
    },
  },
  BaseTheme,
)

export default theme
