import { Flex } from "@vygruppen/spor-react"
import { Box, Stack } from "@vygruppen/spor-react"
import { Link } from "react-router-dom"
import VyLogo from "src/assets/VyLogo"
import Menu, { MenuItem } from "./navigation/Menu"

function Header() {
  const menuItems: MenuItem[] = [
    { to: "/feedback", label: "App og Web" },
    { to: "/app-store-reviews", label: "App Store" },
    { to: "/play-store-reviews", label: "Play Store" },
    { to: "/charts", label: "Grafer" },
    { to: "/activity", label: "Aktivitet" },
  ]

  return (
    <Stack
      direction="row"
      spacing="10"
      as={Flex}
      alignItems="center"
      my={[2, 8]}
      mx={[3, 6, 8]}
      marginTop="6"
    >
      <Link to="/">
        <Box alignItems="center" display="flex">
          <VyLogo />
        </Box>
      </Link>

      <Menu menuItems={menuItems} />
    </Stack>
  )
}

export default Header
